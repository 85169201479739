import { graphql } from '@/__generated/gql';

export const CUSTOMER_DETAILS = graphql(/* GraphQL */ `
  query CustomerDetails($input: CustomerByParamsInput!) {
    customer(input: $input) {
      name
      status
      user {
        id
        firstName
        lastName
        userMetadata {
          name
          id
          value
        }
        bvnStatus {
          image
          bvn
        }
        email
        phone
        kycInformation
        supportingDocument {
          documentName
          file {
            key
            bucket
          }
        }
        blacklistDetails {
          blacklisted
          isInternalBlacklist
          reports {
            id
            reason
            isSameEmail
            isSamePhone
            isSameBvn
            actionBy {
              firstName
              email
              lastName
            }
            createdAt
            expiryDate
          }
        }
      }
      channel {
        type
      }
      bankAccounts {
        accountName
        accountNumber
        bank {
          code
          name
          id
        }
        id
        isDefault
        status
      }
      cards {
        bankName
        email
        expiryDate
        id
        isDefault
        maskedPan
        status
        type
      }
      applications {
        totalCount
        edges {
          node {
            applicationNumber
            completedSteps
            metadata
            id
            status {
              name
            }
            portfolio {
              status {
                name
              }
            }
            createdAt
            amount
            loanDuration
            meta {
              document {
                documentName
                file {
                  url
                  key
                  bucket
                }
                id
                uploadType
              }
              key
              value
            }
            portfolio {
              status {
                name
              }
            }
            uploadedBankStatement {
              password
              url
            }
            chargesAmount
            baseAmount
            fullAmount
            taxAmount
            user {
              firstName
              lastName
              email
            }
          }
        }
      }
      portfolios {
        totalCount
        edges {
          node {
            amount
            createdAt
            amountDisbursed
            amountPaid
            fullAmount
            loanDuration
            portfolioNumber
            application {
              metadata
            }
            id
            metadata {
              key
              value
            }
            status {
              name
            }
            user {
              email
            }
          }
        }
      }
      bvn {
        dateOfBirth
        firstName
        gender
        lastName
        name
        number
        phone
      }
      accountAttribute {
        attribute {
          name
        }
        data
        value
      }
    }
  }
`);
