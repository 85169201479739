import { Fieldset, NumericInput } from '@/components/forms';
import { FullPageLoader } from '@/components/FullPageLoader';
import { FormWrapper } from '@/routes/loan-products/components';
import {
  useGetCustomerBvnDetails,
  useCreateCustomer,
} from '@/services/customers';
import { processGraphQLError } from '@/utils/processGraphQLError';
import { Gender } from '@/__generated/graphql';
import { Button, ButtonGroup, Stack, useToast } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { NewCustomerDetailsFormFields } from '../forms.type';
import { BvnDetails } from './components';

const CustomerDetailsForm = () => {
  const toast = useToast();

  const methods = useForm<NewCustomerDetailsFormFields>({
    mode: 'onChange',
  });
  const { control, formState, watch, handleSubmit } = methods;
  const { errors } = formState;
  const watchedBvn = watch('bvn');

  const {
    customerBvnDetailsLoading,
    customerBvnDetailsResponse,
    customerBvnDetailsError,
  } = useGetCustomerBvnDetails(watchedBvn);

  const { createCustomer, createCustomerError, createCustomerLoading } =
    useCreateCustomer();

  const onSubmit = handleSubmit((data: NewCustomerDetailsFormFields) => {
    const payload = {
      email: data?.email,
      phone: data?.phone,
      firstName: data?.firstName,
      lastName: data?.lastName,
      bvn: data?.bvn,
      gender:
        data.gender.toLowerCase() === 'male' ? Gender?.Male : Gender?.Female,
    };

    createCustomer({ ...payload });
  });

  useEffect(() => {
    if (customerBvnDetailsError || createCustomerError) {
      toast.closeAll();
      toast({
        title: 'Error',
        status: 'error',
        position: 'top',
        isClosable: true,
        description: processGraphQLError(
          customerBvnDetailsError || createCustomerError,
        ),
      });
    }
  }, [customerBvnDetailsError, toast, createCustomerError]);

  return (
    <>
      {createCustomerLoading && <FullPageLoader />}

      <FormProvider {...methods}>
        <FormWrapper
          title="Customer Details"
          description="This information can be created"
          onSubmit={onSubmit}
        >
          <Fieldset
            label="Validate Customer"
            description="Complete the form to begin the customer creation process."
          >
            <Stack spacing={6}>
              <Controller
                control={control}
                name="bvn"
                rules={{
                  minLength: {
                    value: 11,
                    message: 'BVN must be 11',
                  },
                  maxLength: {
                    value: 11,
                    message: 'BVN must be 11',
                  },
                  required: 'BVN is required',
                }}
                render={({ field: { onChange, onBlur, name, value } }) => (
                  <NumericInput
                    autoFocus
                    type="tel"
                    name={name}
                    value={value}
                    maxLength={11}
                    placeholder="Enter customer BVN"
                    onBlur={onBlur}
                    allowLeadingZeros
                    onChange={onChange}
                    label="BVN"
                    thousandSeparator={false}
                    errorMessage={errors?.bvn?.message}
                    isInvalid={!!errors?.bvn?.message}
                  />
                )}
              />
            </Stack>
          </Fieldset>

          {customerBvnDetailsLoading || customerBvnDetailsResponse ? (
            <Fieldset
              label="BVN Details"
              description="This section displays the customer BVN details for identification"
            >
              <BvnDetails
                customerBvnDetailsLoading={customerBvnDetailsLoading}
                customerBvnDetailsResponse={customerBvnDetailsResponse}
              />
            </Fieldset>
          ) : null}

          <ButtonGroup spacing={6}>
            <Button
              px={10}
              ml="auto"
              type="submit"
              disabled={
                customerBvnDetailsLoading ||
                !watchedBvn ||
                watchedBvn?.length < 11
              }
            >
              Proceed
            </Button>
          </ButtonGroup>
        </FormWrapper>
      </FormProvider>
    </>
  );
};

export default CustomerDetailsForm;
